import React, { Component, useState, useEffect } from "react";
import "./interact.css";
import "./intractStyle/intractIpadStyle.css";
import "./intractStyle/intractGlaxyStyle.css";
import "./intractStyle/intractIphoneStyle.css";
import Loader from "../../Images/buffer.gif";
import hand_outer4 from "../../Images/hand_outer4.png";
import LightBulb from "../../Images/lightbulb.png";
import mic from "../../Images/mic.gif";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  LexRuntimeV2Client,
  RecognizeTextCommand,
  RecognizeUtteranceCommand,
} from "@aws-sdk/client-lex-runtime-v2";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
  Collapse,
} from "reactstrap";

import { toast } from "react-toastify";
import {
  postInteractQuestion,
  getEternaviewDetail,
  likeEternaview,
  getAnsweredQuestions,
  verifyUserSubscription,
  getParticularQuestionDetail,
  getInteractParticularQuestionDetail,
  getQueryDetail,getChatGptViewerQuestions,ChatGPtText
} from "../../redux/actions";
import Moment from "moment";
import { Base64 } from "js-base64";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import RecorderTest from "../../components/RecorderTest";
import InteractVideoTest from "./components/interactVideoTest";
import InteractAudio from "./components/interactAudio";
import sample_audio from "../../Sounds/no_sound.mp3";
import $, { type } from "jquery";
import { isMobile, isTablet } from "react-device-detect";
const client = new LexRuntimeV2Client({
  region: "us-east-1",
  credentials: {
    accessKeyId: process.env.REACT_APP_LEX_ACCESS_KEYS,
    secretAccessKey: process.env.REACT_APP_LEX_SECRET_KEYS,
  },
});

class InteractOneTestscreenLive extends Component {
  constructor(props) {
    //alert(window.location.origin);

    super(props);
    this.myRef = React.createRef();
    this.timeIterval = ""
    this.state = {
      sessionUID:null,
      isApiCallSuccess:false,
      isPotraitMode:false,
      isTipsOpen: localStorage.getItem("tipsShown") !== "false",
      recording: false,
      recordedAudio: {},
      show_input: false,
      show_modal_input: false,
      searchinput: "",
      videoUrl: "",
      videoType: "",
      orgUrl: "",
      isPleasentUR:false,
      sharedDetails:{},
      detail: {},
      micRecording: false,
      micModalRecording: false,
      moduleQuestions: [],
      showLike: false,
      askQuestionBit: false,
      permission: "",
      base64regex:
        /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/,
      accesspermission: false,
      showHints: false,
      maxInteract: false,
      hideInteractOptions: false,
      no_transcoding: false,
      noInteract: false,
      blobURL: "",
      botResponse: "",
      category: "",
      screen_mode: "landscape",
      showUtterance: false,
      utteranceText: "",
      sizeAlert: "",
      modeAlert: "",
      stylePath: window.location.origin + "/css/intract/" + "mobileView.css",
      shallUpdate: true, //this is taken to prevent from re-rendder issue if you set it false then also set to true on end of task
      isDivOpen: { isKeyboardOpen: false, isTopicOpen: false },
      toggleDiv: { isKeyboardOpen: false, isTopicOpen: false },
      styleStatus: { orientation: "portrait" },
      manageStyle: { orientation: "portrait" },
      screenWidth: window.screen.width,
      screenHeight: "",
      buttonStyle: { height: null, width: null },
      boatID:"",
      botAliasId: "TSTALIASID",
      secondPlayMuteStatus:true,
    };
    this.parentRef = React.createRef();
    this.childRef = React.createRef();
    // this.onInputchange = this.onInputchange.bind(this);
    //this.getTextResponseFromRecorder =             this.getTextResponseFromRecorder.bind(this);
    this.baseName = '';
    this.manageBaseUrl();
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);

  }

  checkEligibility(){

    this.props.verifyUserSubscription(this.props.auth.loginUserToken, (res) => {
      if (res.is_active == false) {
        let checkTipPopStatus = localStorage.getItem("tipsShown");
        localStorage.clear();
        localStorage.setItem("tipsShown",checkTipPopStatus);

      } else {
        if (res.data) {
          if (
            res.data.updated_details.no_of_query == 0 &&
            res.data.updated_details.grace_no_of_query == 0
          ) {
            this.setState({ maxInteract: true, hideInteractOptions: true });
          }
        }
      }
    });
    this.props.getQueryDetail(this.props.match.params.id, "", (result) => {
      if (result.no_of_query == 0) {
        this.setState({ maxInteract: true, hideInteractOptions: true });
      }
    });
  }

  componentDidMount() {
    console.log(this.props.match.params.id,"<- this.props.match.params.id");
    console.log( parseInt(this.props.match.params.id, 10),"<- this.props.match");


    
    // this.getAnsweredQuetions()
    this.manageOrientation()
    this.getQueryParam()
    window.addEventListener("orientationchange", this.manageOrientation);

    window.addEventListener("resize", this.manageOrientation);
    window.addEventListener("visibilitychange", this.handleVisibilityChange);


    if (this.props.match.params.id || this.props.match.params.author) {


      if (
        this.state.base64regex.test(this.props.match.params.id) &&
        this.state.base64regex.test(this.props.match.params.author && isNaN(this.props.match.params.id)==true)
      ) {
        this.props.match.params.id = Base64.decode(this.props.match.params.id);
        this.props.match.params.author = Base64.decode(
          this.props.match.params.author
        );
      }
      this.setState({ permission: this.props.match.params.permission });

      this.getMyDetail(this.props.match.params.permission);
    } else {
      this.getMyDetail("");
    }
    this.checkEligibility()
  }

/* Handle popup functions for freeze start */
  componentWillUnmount() {
    document.removeEventListener("visibilitychange", this.handleVisibilityChange);
  }


  getQueryParam = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get("source"); // Retrieve the value of "source"
    if(source==='external')
    {
      this.setState({ isTipsOpen: true });

    }
  }

  handleVisibilityChange() {

    // Update the state based on the visibility of the tab
    if (document.visibilityState === "visible") {
      console.log("Tab is visible again.");
      this.setState({ isTipsOpen: true });
      localStorage.setItem("tipsShown","true");


    } else {
      console.log("Tab is hidden.");
      this.setState({ isTipsOpen: false });
      // Additional logic for when the tab is hidden
    }
  }
/* Handle popup functions for freeze end */
  manageBaseUrl() {
    const { location } = this.props;
    const { pathname } = location;
    const splitLocation = pathname.split("/");
   
    if(splitLocation[1]=='dating')
    {
     
      this.baseName = 'dating';
    }
    else
    {

      this.baseName = 'home';
    }

  }

  closePopUp = (e) => {
    e.preventDefault();

    this.setState({ maxInteract: false });
  };

  manageOrientation = () => {
    let landscape = window.matchMedia("(orientation: landscape)");
    let portrait = window.matchMedia("(orientation: portrait)");
    if (landscape.matches === true) {
      if(this.state.sharedDetails?.screen_mode == "portrait"){
        this.setState({ isPotraitMode: false});
      }
      this.setState({ styleStatus: { orientation: "landscape" } });
      // if(isMobile){
      //   let topicButtonStyle
      //   if(this.state.screenWidth >= 840) topicButtonStyle = 71
      //   else topicButtonStyle = 75
      //   this.setState({
      //     buttonStyle:{
      //       width:topicButtonStyle,
      //     }
      //   });
      // }
    } else if (portrait.matches === true) {
      this.setState({ styleStatus: { orientation: "portrait" } });
      if (this.state.screenWidth) {
        if(parseInt(this.state.screenWidth) < 650){
          this.setState({ screenHeight: (parseInt(this.state.screenWidth) * 16) / 9});
        }else{
          const reduceWidth = this.state.screenWidth*0.27
          this.setState({ screenHeight: (parseInt(this.state.screenWidth - reduceWidth) * 16) / 9});
        }
        if(this.state.sharedDetails?.screen_mode == "portrait"){
          this.setState({ isPotraitMode: true});
        }
      }
    }
  };

  likeView = (e) => {
    e.preventDefault();

    this.props.likeEternaview(
      this.props.match.params.id,
      this.props.auth.loginUserToken,
      (res) => {
        if (!res.detail) {
          this.setState({ showLike: true });
          setTimeout(() => this.setState({ showLike: false }), 3000);
        }
      }
    );
  };
  getAnsweredQuetions(res){
    this.props.getAnsweredQuestions(
      this.props.match.params.author,
      this.props.match.params.id,
      "",
      (response) => {
        let allquestions = [];
        if (response.data && response.data.length > 0) {
          response.data.map((con, index) => {
            if (con.id == res.category_id) {
              con.modules.map((subcat, ind) => {
                let cat = subcat.subcategory_name;

                if (
                  con.category_name == "Dating" &&
                  cat !=
                    "Interaction Facilitation & Pleasantries (required)"
                ) {
                  allquestions.push(subcat);
                }

                if (
                  con.category_name == "Legacy" &&
                  cat != "Interaction Facilitation Part 1 (required)" &&
                  cat != "Interaction Facilitation Part 2" &&
                  cat != "Pleasantries"
                ) {
                  allquestions.push(subcat);
                }

                if (
                  con.category_name == "Squirrel" &&
                    cat !='Interaction Facilitation & Pleasantries - Squirrel'){
                    allquestions.push(subcat);

                } 




              });
            }
          });
          this.setState({ moduleQuestions: allquestions });
        }
      }
    );
  }
  updateVjsSrc(){
  const video_url =  sessionStorage.getItem("node_url")
    this.setState({
      isPleasentUR:false,
      orgUrl: video_url,
      videoType: "application/x-mpegURL",
    });
  }

  getMyNodeDetail(permission) {
    this.props.getEternaviewDetail(
      this.props.match.params.id,
      permission,
      this.props.auth.loginUserToken != ""
        ? this.props.auth.loginUserToken
        : "",
      (res) => {
        if (res.detail) {
          toast(res.detail, {
            position: "top-right",
            autoClose: 3000,
            type: "error",
          });
        }
        if (
          this.props.auth.loginUserToken == "" &&
          res.access_permission == "restricted"
        ) {
          toast("You do not have permission to perform this action.", {
            position: "top-right",
            autoClose: 3000,
            type: "error",
          });
        } else {
          if (res.is_active == false) {
            this.setState({ noInteract: true });
          } else {
            if(!res.screen_mode){
              res.screen_mode = "landscape"
            }
            this.setState({
              isApiCallSuccess:true,
              sharedDetails:res,
              orgUrl: res.nodding_video,
              detail: res,
              category: res.category_name,
            });
            if(
              res?.screen_mode == "portrait" &&
              this.state.styleStatus.orientation == "portrait"
            ){
              this.setState({ isPotraitMode: true });
            }
            this.setState({ no_transcoding: false });
            if (res.type != "audio") {
              const transcode_url = res.nodding_video;
              if (transcode_url) {
                let uri = transcode_url;
                let parts = uri.split("?");
                let query = parts[1].split("&"); // "height=1080&width=1920"
                var width = parseInt(query[1].split("=")[1]);
                var height = parseInt(query[0].split("=")[1]);
                if (res.screen_mode != "portrait") {
                  this.setState({ screen_mode: "landscape" });
                } else {
                  this.setState({ screen_mode: "portrait" });
                }
              }
            } else {
              document
                .getElementById("audioIteractor")
                .setAttribute("src", sample_audio);
            }

            if(res.pleasant_url){
              this.setState({
                isPleasentUR:true,
                orgUrl: res.pleasant_url,
                videoType: "application/x-mpegURL",
              });
              sessionStorage.setItem("node_url",res.nodding_video)
            }else{
              if(res.nodding_video){
                clearInterval(this.timeIterval)
              }
              this.setState({
                isPleasentUR:false,
                orgUrl: res.nodding_video,
                videoType: "application/x-mpegURL",
              });
            }

      
            if (res.access_permission == "restricted") {
              this.setState({ accesspermission: true });
            }
            this.getAnsweredQuetions(res)
          }
        }
      }
    );
  }

  getMyDetail(permission) {
    this.props.getEternaviewDetail(
      this.props.match.params.id,
      permission,
      this.props.auth.loginUserToken != ""
        ? this.props.auth.loginUserToken
        : "",
      (res) => {
        if (res.detail) {
          toast(res.detail, {
            position: "top-right",
            autoClose: 3000,
            type: "error",
          });
        }
        if (
          this.props.auth.loginUserToken == "" &&
          res.access_permission == "restricted"
        ) {
          toast("You do not have permission to perform this action.", {
            position: "top-right",
            autoClose: 3000,
            type: "error",
          });
        } else {
          if (res.is_active == false) {
            this.setState({ noInteract: true });
          } else {
            if(!res.screen_mode){
              res.screen_mode = "landscape"
            }
            this.setState({
              isApiCallSuccess:true,
              sharedDetails:res,
              orgUrl: res.nodding_video,
              detail: res,
              category: res.category_name,
            });
            if(
              res?.screen_mode == "portrait" &&
              this.state.styleStatus.orientation == "portrait"
            ){
              this.setState({ isPotraitMode: true });
            }
            this.setState({ no_transcoding: false });
            if (res.type != "audio") {
              const transcode_url = res.nodding_video;
              if (transcode_url) {
                let uri = transcode_url;
                let parts = uri.split("?");
                let query = parts[1].split("&"); // "height=1080&width=1920"
                var width = parseInt(query[1].split("=")[1]);
                var height = parseInt(query[0].split("=")[1]);
                if (res.screen_mode != "portrait") {
                  this.setState({ screen_mode: "landscape" });
                } else {
                  this.setState({ screen_mode: "portrait" });
                }
              }
            } else {
              document
                .getElementById("audioIteractor")
                .setAttribute("src", sample_audio);
            }

            if(res.pleasant_url){
              this.setState({
                isPleasentUR:true,
                orgUrl: res.pleasant_url,
                videoType: "application/x-mpegURL",
              });
              sessionStorage.setItem("node_url",res.nodding_video)
            }else{
              if(!res.nodding_video){
                this.timeIterval = setInterval(() => {
                  this.getMyNodeDetail(this.props.match.params.permission)
                }, 3000); 
              }else
              this.setState({
                isPleasentUR:false,
                orgUrl: res.nodding_video,
                videoType: "application/x-mpegURL",
              });
            }

      
            if (res.access_permission == "restricted") {
              this.setState({ accesspermission: true });
            }
            this.getAnsweredQuetions(res)
          }
        }
      }
    );
  }

  
  togglePlayer() {
    let video = videojs("secondVideo");
    let playerIcon = document.getElementById("playerIcon");
    if (video.paused()) {
      video.play();
      playerIcon.classList.remove("fa-play");
      playerIcon.classList.add("fa-pause");
    } else {
      video.pause();
      playerIcon.classList.remove("fa-pause");
      playerIcon.classList.add("fa-play");
    }
  }

  questionVideo = (id) => {
    
    /* var player = videojs('vid2');
    
            player.fill(true); */

    $(".text_to_speech").hide();

    $(".play_pausebutton").hide();

    this.setState({ no_transcoding: false,  isApiCallSuccess:false });
    let token = this.props.auth.loginUserToken;

    let url = `author-question/${id}/?author_id=${this.state.detail.author}&query=yes&view_id=${this.props.match.params.id}`;

    if (token) {
      if (localStorage.getItem("super")) {
        url = `author-question/${id}/?author_id=${this.state.detail.author}&view_id=${this.props.match.params.id}&user_type=admin`;
        token = localStorage.getItem("super");
      }
      this.props.getParticularQuestionDetail(url, token, (res) => {
        let playerIcon = document.getElementById("playerIcon");
        if (playerIcon) {
          playerIcon.classList.remove("fa-play");
          playerIcon.classList.add("fa-pause");
        }
        $(".play_pausebutton").show();

        this.setState({ showHints: false });
        $("#topicPopup").hide();
        if (res.data && res.data.answer.id) {
          this.setState({ recordedAudio: {} });

          this.setState({
            recording: true,
            searchinput: "",
            show_input: false,
            isApiCallSuccess:true,
          });

          if (res.data.answer.video.trans_coded_url != null) {
            if(!res.data.answer.video.screen_mode){
              res.data.answer.video.screen_mode ="landscape"
            }
            this.setState({
           
              sharedDetails: res.data.answer.video,
              videoUrl: res.data.answer.video.trans_coded_url,
              videoType: "application/x-mpegURL",
            });

            const transcode_url = res.data.answer.video.trans_coded_url;
            let uri = transcode_url;
            let video = videojs("secondVideo");
            video.play();
            this.setState({ secondPlayMuteStatus:false  });

            let parts = uri.split("?");

            let query = parts[1].split("&"); // "height=1080&width=1920"
            var width = query[1].split("=")[1];
            var height = query[0].split("=")[1];
            if (width > height) {
              //landscape
              $("#vdosection").addClass("videoLandscape");
              $("#vdosection").removeClass("videoPortrait");
            } else {
              //portrait
              $("#vdosection").addClass("videoPortrait");
              $("#vdosection").removeClass("videoLandscape");
            }
          } else if (res.data.answer.video.type == "audio") {
            this.setState({ videoUrl: res.data.answer.video.media_file });
          } else {
            this.setState({ no_transcoding: true });
          }
        }
      });
    } else {
      if (localStorage.getItem("super")) {
        url = `question/detail/${id}/?author_id=${this.state.detail.author}&user_type=admin&view_id=${this.props.match.params.id}`;
      } else {
        url = `question/detail/${id}/?author_id=${this.state.detail.author}&query=yes&view_id=${this.props.match.params.id}`;
      }

      this.setState({isApiCallSuccess:false})

      this.props.getInteractParticularQuestionDetail(url, "", (res) => {

        $(".playerIcon").removeClass("fa-play");
        $(".playerIcon").addClass("fa-pause");
        $(".play_pausebutton").show();

        this.setState({ showHints: false,  isApiCallSuccess:true, });
        if (res.data && res.data.answer.id) {
          this.setState({ recordedAudio: {} });
          $("#topicPopup").hide();
          if (res.data && res.data.answer.id) {
            this.setState({
              recording: true,
              searchinput: "",
              show_input: false,
            });
            let playerIcon = document.getElementById("playerIcon");
            if (playerIcon) {
              playerIcon.classList.remove("fa-play");
              playerIcon.classList.add("fa-pause");
            }
            $(".play_pausebutton").show();
            if (res.data.answer.video.trans_coded_url != null) {
              const transcode_url = res.data.answer.video.trans_coded_url;
              let uri = transcode_url;

              let parts = uri.split("?");

              let query = parts[1].split("&"); // "height=1080&width=1920"
              var width = query[1].split("=")[1];
              var height = query[0].split("=")[1];
              if (width > height) {
                //landscape
                $("#vdosection").addClass("videoLandscape");
                $("#vdosection").removeClass("videoPortrait");
              } else {
                //portrait
                $("#vdosection").addClass("videoPortrait");
                $("#vdosection").removeClass("videoLandscape");
              }
              this.setState({
                videoUrl: res.data.answer.video.trans_coded_url,
                videoType: "application/x-mpegURL",
              });
            } else if (res.data.answer.video.type == "audio") {
              this.setState({ videoUrl: res.data.answer.video.media_file });
            } else {
              this.setState({ no_transcoding: true });
            }
          }
        }
      });
    }
    // this.checkEligibility()
  };

  interactResponseReceive(response) {
    if (response == true) {
      this.setState({ recording: false, askQuestionBit: false });
    }
  }
  hideTranscript() {
    this.setState({
      showUtterance: false,
      utteranceText: "",
    });
  }
  /* Click to talk */
  receiveTextFromWhisper(data){
    $(".text_to_speech").show();
    // console.log(data);
    this.setState({
      showUtterance: true,
      utteranceText: data?.data?.query,
    });
  }
  /* Click to talk */
  getTextResponseFromRecorder(data) {
    // $(".text_to_speech").show();
    // // console.log(data);
    // this.setState({
    //   showUtterance: true,
    //   utteranceText: data.key.slice(1, -1),
    // });
    this.sendToGet(data.key.slice(1, -1), data.interpretations, "text", true,data);
  }
  sendToGet(question, interpretations, query_type, query_is_audio = false,data) {
    this.setState({
      recording: false,
    });
    let formData = new FormData();

    formData.append("question", question);
    formData.append("language", 1);
    formData.append("apikey", "dsDKL4342jertn6438");
    formData.append("type", "normal");
    formData.append("duration", "0");
    formData.append("interact_user_id", this.props.match.params.author);
    formData.append("interact_view_id", this.props.match.params.id);
    formData.append("interpretations", JSON.stringify(interpretations));
    formData.append("query_type", query_is_audio ? "audio" : query_type); // query_type:audio
    formData.append('category_type',this.state.category);

    if(data?.login_uuid){
      formData.append("login_uuid", JSON.stringify(data.login_uuid));
      this.getQuentionsBasedOnwhisper(formData)
    }else{
      this.getQuentionsBasedOnlex(formData)
    }
    this.setState({
      searchinput: question,
    });
  
  }
  
  async getQuentionsBasedOnlex(formData,question){
    this.props.postInteractQuestion(
      formData,
      this.props.auth.loginUserToken ? this.props.auth.loginUserToken : "",
      (res) => {
        this.setState({ shallUpdate: false });
        this.setState({ askQuestionBit: true, no_transcoding: false });

        this.setState({ recordedAudio: {} });
        this.setState({ showHints: false });
        this.setState({
          searchinput: question,
        });
        $("#keyboardPopup").hide();
        this.setState({ shallUpdate: true });
        if (res.data) {
          this.setState({
            recording: true,
            searchinput: "",
            show_input: false,
            askQuestionBit: false,
          });

          if (res.data && res.data.data.answer.video.type == "audio") {
            // this.setState({ videoUrl: res.data.data.answer.video.media_file });
            // $(".audioInterpretor").play();
            document
              .getElementById("audioIteractor")
              .setAttribute("src", res.data.data.answer.video.media_file);
            document.getElementById("audioIteractor").play();
          } else {
            const transcode_url = res.data.data.answer.video.trans_coded_url;
            let uri = transcode_url;
            let parts = uri.split("?");
            let query = parts[1].split("&");
            var width = query[1].split("=")[1];
            var height = query[0].split("=")[1];
            if (width > height) {
              //landscape
              $("#vdosection").addClass("videoLandscape");
              $("#vdosection").removeClass("videoPortrait");
            } else {
              //portrait
              $("#vdosection").addClass("videoPortrait");
              $("#vdosection").removeClass("videoLandscape");
            }

            this.setState({
              videoUrl: res.data.data.answer.video.trans_coded_url,
              videoType: "application/x-mpegURL",
            });
            let video = videojs("secondVideo");
            video.play();
            this.setState({ secondPlayMuteStatus:false  });
          }
          let playerIcon = document.getElementById("playerIcon");
          if (playerIcon) {
            playerIcon.classList.remove("fa-play");
            playerIcon.classList.add("fa-pause");
          }
          // $(".playerIcon").removeClass("fa-play");
          // $(".playerIcon").addClass("fa-pause");
          // $(".play_pausebutton").show();
        }
      }
    );
  }

  async getQuentionsBasedOnwhisper(formData){
    this.props.getChatGptViewerQuestions(
      formData,
      this.props.auth.loginUserToken ? this.props.auth.loginUserToken : "",
      (res) => {
        this.setState({ shallUpdate: false });
        this.setState({ askQuestionBit: true, no_transcoding: false });

        this.setState({ recordedAudio: {} });
        this.setState({ showHints: false });
  
        $("#keyboardPopup").hide();
        this.setState({ shallUpdate: true });
        if (res.data) {
          this.setState({
            recording: true,
            searchinput: "",
            show_input: false,
            askQuestionBit: false,
          });

          if (res.data && res.data.data.answer.video.type == "audio") {
            // this.setState({ videoUrl: res.data.data.answer.video.media_file });
            // $(".audioInterpretor").play();
            document
              .getElementById("audioIteractor")
              .setAttribute("src", res.data.data.answer.video.media_file);
            document.getElementById("audioIteractor").play();
          } else {
            const transcode_url = res.data.data.answer.video.trans_coded_url;
            let uri = transcode_url;
            let parts = uri.split("?");
            let query = parts[1].split("&");
            var width = query[1].split("=")[1];
            var height = query[0].split("=")[1];
            if (width > height) {
              //landscape
              $("#vdosection").addClass("videoLandscape");
              $("#vdosection").removeClass("videoPortrait");
            } else {
              //portrait
              $("#vdosection").addClass("videoPortrait");
              $("#vdosection").removeClass("videoLandscape");
            }

            this.setState({
              videoUrl: res.data.data.answer.video.trans_coded_url,
              videoType: "application/x-mpegURL",
            });
            let video = videojs("secondVideo");
            video.play();
            this.setState({ secondPlayMuteStatus:false  });
          }
          let playerIcon = document.getElementById("playerIcon");
          if (playerIcon) {
            playerIcon.classList.remove("fa-play");
            playerIcon.classList.add("fa-pause");
          }
          // $(".playerIcon").removeClass("fa-play");
          // $(".playerIcon").addClass("fa-pause");
          // $(".play_pausebutton").show();
        }
      }
    );
  }

  /* Ask Question */
  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.submitQuestion(e);
    }
  };

  submitQuestion = async (e, ispassed = false, key = "") => {
    this.setState({
      toggleDiv: {
        isKeyboardOpen: !this.state.toggleDiv.isKeyboardOpen,
      },
    });
    if (!ispassed) e.preventDefault();
    var keyword = ispassed ? key : $("#typeInQuetion").val();
    $(".text_to_speech").hide();

    if (keyword.trim() === "") {
      toast("Please enter your question.", {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
      return false;
    }
    if (document.getElementById("audioIteractor")) {
      document
        .getElementById("audioIteractor")
        .setAttribute("src", sample_audio);
      document.getElementById("audioIteractor").play();
    }
    const sessionUID= Math.floor(Math.random() * 1000000)
    let formData = new FormData();
    formData.append('text', keyword);
    formData.append('login_uuid',sessionUID)
    if(this.state.category=='Legacy')
    {
        formData.append('type',  'legacy');
    }
    else if(this.state.category=='Dating')
    {
        formData.append('type',  'Dating');
    }
    else 
    {
        formData.append('type',  'Squirrel');

    }
    await this.props.ChatGPtText(formData,this.props.auth.loginUserToken,async(res)=>{
    // console.log(res,"<- Resp ");
    })

    if(this.state.category == "Legacy")
    {
      this.setState({ boatID: "RMEHNYXF57" });
    }
    else if(this.state.category == "Dating")
    {
      this.setState({ boatID: "QPSJB0MH2O" });
      this.setState({ botAliasId: "YJ0MHSCRFG" });

    }
    else if(this.state.category == "Squirrel")
    {
      this.setState({ boatID: "ZZQU09YS8F" });

    }

    const command = new RecognizeTextCommand({
      botAliasId: "TSTALIASID",
      botAliasId:this.state.botAliasId,
      // botId: this.state.category == "Dating" ? "QPSJB0MH2O" : "RMEHNYXF57",
      botId:this.state.boatID,
      localeId: "en_US",
      text: keyword,
      sessionId: "043758711856848",
    });

    const response = await client.send(command);
    if (response.interpretations && response.interpretations.length > 0) {
      $("#typeInQuetion").val("");
      this.setState({ showUtterance: false });
      this.sendToGet(keyword, response.interpretations, "text",false,{login_uuid:sessionUID});
    }
  };

  // scrollToCollapse = (id)=>{
  //     const element = document.getElementById("topic_div");
  //     element.scrollTo({ top: element.scrollHeight, behavior: 'smooth',block: 'start' })
  //   }
  scrollToChild = (id)=>{
    const parentDiv = this.parentRef.current;
    const element = document.getElementById(`collapseOne${id}`);
    element.scrollTo({ top: element.offsetTop - parentDiv.offsetTop, behavior: 'smooth',inline: "nearest", })
  }

  // scrollToChild = () => {
  //   const parentDiv = this.parentRef.current;
  //   const childDiv = this.childRef.current;
  //   if (parentDiv && childDiv) {
  //     parentDiv.scrollTo({
  //       top: childDiv.offsetTop,
  //       behavior: "smooth",
  //       block: "start",
  //     });
  //     //   parentDiv.scrollTop = childDiv.offsetTop - parentDiv.offsetTop;
  //   }
  // };

  render() {
    const {
      show_input,
      orgUrl,
      videoUrl,
      videoType,
      recording,
      micRecording,
      isApiCallSuccess,
      moduleQuestions,
      detail,
      showLike,
      showHints,
      no_transcoding,
      blobURL,
      category,
      sharedDetails,
      boatID,
      secondPlayMuteStatus,
    } = this.state;
    return (
      <div>
        <section
          className={
            this.state.styleStatus.orientation &&
            this.state.styleStatus.orientation === "portrait"
              ? "interact_screen_section portrait_video_outer"
              : "interact_screen_section"
          }
          ref={(node) => {
            if (node &&  this.state.styleStatus.orientation === "landscape") {
              let div
              div  = document.getElementById("myVideo");
              if(div?.offsetWidth == 0){
                div =document.getElementById("secondVideo");
              }
              const width = div?.offsetWidth;
              const height = width *9 /16
              if (height) {
                node.style.setProperty(
                  "height",
                  `${height}px`,
                  "important"
                );
              }
            }
            if (node &&  this.state.styleStatus.orientation === "portrait" && (isMobile || isTablet)) {
              node.style.setProperty(
                "height",
                `auto`,
                "important"
              );
          }
          }}
          // className="interact_screen_section portrait_video_outer"
        >
          <div
            ref={(node) => {
              if (node &&(isMobile||isTablet)&&  this.state.styleStatus.orientation === "landscape") {
                  const containerWidth = window.screen.height
                 const attainWidth =  window.screen.height *16 /9
                if (attainWidth) {
                  node.style.setProperty(
                    "max-width",
                    `${attainWidth}px`,
                    "important"
                  );
                }
              }
            }}
          
          
          className="container">
            {/* <video className="interact_video" controls>
                            <source
                                src="http://media.w3.org/2010/05/sintel/trailer.mp4"
                                type="video/mp4"
                            />
                            Your browser does not support the video tag.
                        </video> */}

            {!this.state.noInteract ? (
              <>
                {/* {micRecording == true && <div className="recording-interact"><img src={mic} hover-tooltip="Click to ask audio query to eternaview." tooltip-position="top"  /></div>} */}
                {micRecording == true && (
                  <div className="recording-interact">
                    <img src={mic} tooltip-position="top" />
                  </div>
                )}

                {detail && detail.type == "audio" && (
                  <InteractAudio
                    setAudioTranscript={(val) => this.hideTranscript()}
                    setInteractResponse={(val) =>
                      this.interactResponseReceive(val)
                    }
                    recording={recording}
                    orgUrl={orgUrl}
                    videoUrl={videoUrl}
                    detail={detail}
                  />
                )}
                {detail && detail.type == "video" && (
                  <InteractVideoTest
                  isPleasentUR = {this.state.isPleasentUR}
                    deviceDetails={{
                      deviceOrentation: this.state.styleStatus.orientation,
                      height: this.state.screenHeight,
                      width: this.state.screenWidth,
                    }}
                    setAudioTranscript={(val) => this.hideTranscript()}
                    setInteractResponse={(val) =>
                      this.interactResponseReceive(val)
                    }
                    isApiCallSuccess={isApiCallSuccess}
                    recording={recording}
                    orgUrl={orgUrl}
                    videoUrl={videoUrl}
                    videoType={videoType}
                    no_transcoding={no_transcoding}
                    detail={sharedDetails}
                    updateVjsSrc={()=>{this.updateVjsSrc()}}
                    secondPlayMuteStatus={secondPlayMuteStatus}
                  />
                )}
                {Object.keys(detail).length == 0 && (
                  <InteractVideoTest
                    deviceDetails={{
                      deviceOrentation: this.state.styleStatus.orientation,
                      height: this.state.screenHeight,
                      width: this.state.screenWidth,
                    }}
                    setAudioTranscript={(val) => this.hideTranscript()}
                    setInteractResponse={(val) =>
                      this.interactResponseReceive(val)
                    }
                    recording={recording}
                    orgUrl={orgUrl}
                    videoUrl={videoUrl}
                    videoType={videoType}
                    no_transcoding={no_transcoding}
                    detail={sharedDetails}
                    secondPlayMuteStatus={secondPlayMuteStatus}
                  />
                )}
              </>
            ) : recording == true && no_transcoding == true ? (
              <div className="processed_video_outer">
                <img src={Loader} />
                <p>The video is still being processed. </p>
                <p>It should be ready in a couple minutes or less.</p>
              </div>
            ) : (
              <div className="processed_video_outer">
                <p>Sorry, this eternaview is deactivated.</p>
                <p>It is not accessible at the moment.</p>
              </div>
            )}
          </div>

          <div
            className={
              this.state.isPotraitMode
                ? "interact_buttons_divPotrait"
                : "interact_buttons_div"
            }
          >
            <div
              // ref={(node) => {
              //   if (node && this.state.buttonStyle) {
              //     if (this.state.buttonStyle.height) {
              //       node.style.setProperty(
              //         "height",
              //         `${this.state.buttonStyle.height}px`,
              //         "important"
              //       );
              //     }
              //   }
              //   if (node && this.state.buttonStyle.width) {
              //     node.style.setProperty(
              //       "max-width",
              //       `${this.state.buttonStyle.width}%`,
              //       "important"
              //     );
              //   }
              // }}
              className="container interact_btn_container11"
              ref={(node) => {
                if (node &&(isMobile||isTablet)&&this.state.styleStatus.orientation === "landscape") {
                    // node.style.setProperty(
                    //   "height",
                    //   `${window.screen.height}px`,
                    //   "important"
                    // );
                    const containerWidth = window.screen.height
                   const attainWidth =  window.screen.height *16 /9
                  if (attainWidth) {
                    node.style.setProperty(
                      "max-width",
                      `${attainWidth}px`,
                      "important"
                    );
                  }
                }
              }}
            >
              <div className="row">
                <div className="col-md-12">
                  <div className="ask_question_popup">
                    {this.state.showUtterance == true && (
                      <p>{this.state.utteranceText}</p>
                    )}
                  </div>

                  {/* <div className="ask_question_popup">
                                        <p>{this.state.utteranceText}</p>
                                    </div> */}
                  <div className="interact_buttons_inner">
                    {this.state.toggleDiv.isKeyboardOpen && (
                      <div id="askquestion" className="ask_question_div">
                        <input
                          type="text"
                          placeholder="Type in your question:"
                          id="typeInQuetion"
                          onKeyDown={this._handleKeyDown}
                        />
                        <div className="ask_btn_div">
                          <a
                            className="topic_btn"
                            onClick={this.submitQuestion}
                            href="#"
                          >
                            Ask Question
                          </a>
                        </div>
                      </div>
                    )}

                    {!this.state.hideInteractOptions && (
                      <div className="interact_btn_left">
                        <a
                          onClick={() => {
                            this.setState({
                              toggleDiv: {
                                isKeyboardOpen:
                                  !this.state.toggleDiv.isKeyboardOpen,
                              },
                            });
                          }}
                          style={{ cursor: "pointer" }}
                          className="keyboard_icon"
                          id="keyborad"
                        >
                          <i className="far fa-keyboard"></i>
                        </a>
                        <RecorderTest
                        textFromWhisper = {(val)=>{this.receiveTextFromWhisper(val)}}
                          setTranscript={(val) => this.hideTranscript()}
                          getTextAudio={(val) =>
                            this.getTextResponseFromRecorder(val)
                          }
                          setBotResponse={(val) => this.responseReceive(val)}
                          client={client}
                          category={category}
                          setBlobURL={blobURL}
                          boatID={boatID}
                          baseName={this.baseName}
                        />
                        {/*                                             <a className="click_talk_btn" href="#"><i className="far fa-microphone"></i>Click To Talk</a>
                         */}{" "}
                      </div>
                    )}

                    <div className="interact_btn_center">
                      {recording == true &&
                        no_transcoding == false &&
                        detail.type == "video" && (
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.togglePlayer();
                            }}
                            className="keyboard_icon"
                          >
                            <i
                              id="playerIcon"
                              class="playerIcon fas fa-pause"
                            ></i>
                          </a>
                        )}
                    </div>
                    <div className="interact_btn_right">
                      {this.state.toggleDiv.isTopicOpen && (
                        <div
                          style={
                            this.state.styleStatus.orientation === "portrait" &&
                            sharedDetails?.screen_mode == "landscape"
                              ? { top: 80, bottom: "inherit" }
                              : {}
                          }
                          className="topic_div"
                          id="topic_div"
                          ref={this.parentRef}
                        >
                          <a
                            id="closeButton11"
                            onClick={() => {
                              this.setState({
                                toggleDiv: {
                                  isTopicOpen:
                                    !this.state.toggleDiv.isTopicOpen,
                                },
                              });
                            }}
                            className="topic_close"
                          >
                            <i className="fa fa-times" aria-hidden="true"></i>
                          </a>
                          <div className="bs-example">
                            <div
                              className="accordion accordion-title "
                              id="accordionExample"
                            >
                              {moduleQuestions?.map((subcat, ind) => {
                                return (
                                  <div className="card " key={subcat.id}>
                                    <div
                                      className="card-header"
                                      id={"headingOne" + ind}
                                      ref={this.childRef}
                                      onClick={()=>{this.scrollToChild(ind)}}
                                      // onClick={()=>this.scrollToCollapse("collapseOne" + ind)}
                                    >
                                      <h2 className="mb-0">
                                        <button
                                          type="button"
                                          className="btn btn-link"
                                          data-toggle="collapse"
                                          data-target={"#collapseOne" + ind}
                                        >
                                          <span className="buttonsubcat_span">
                                            {subcat.subcategory_name}
                                          </span>
                                          <i className="fa fa-angle-down"></i>{" "}
                                        </button>
                                      </h2>
                                    </div>
                                    <div
                                      id={"collapseOne" + ind}
                                      className="collapse accordion-content"
                                      aria-labelledby={"headingOne" + ind}
                                      data-parent="#accordionExample"
                                    >
                                      <div className="card-body">
                                        {subcat.questions.map((ques, index) => {
                                          return (
                                            <div key={ques.id}>
                                              <button
                                                id={ques.id}
                                                className="custom_accordion_button"
                                                onClick={() => {
                                                  this.questionVideo(ques.id);
                                                  this.setState({
                                                    showUtterance: false,
                                                    toggleDiv: {
                                                      isTopicOpen:
                                                        !this.state.toggleDiv
                                                          .isTopicOpen,
                                                    },
                                                  });
                                                }}
                                              >
                                                {ques.question}{" "}
                                              </button>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                      {!this.state.hideInteractOptions && (
                        <a
                          onClick={() => {
                            this.setState({
                              toggleDiv: {
                                isTopicOpen: !this.state.toggleDiv.isTopicOpen,
                              },
                            });
                          }}
                          hover-tooltip="Click any question to hear answer."
                          style={{ cursor: "pointer" }}
                          className="topic_btn"
                          id="topic_btn"
                        >
                          Topics
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          style={
            sharedDetails?.screen_mode == "landscape" &&
            this.state.styleStatus.orientation == "portrait"
              ? { width: "100%" }
              : sharedDetails?.screen_mode == "landscape" &&
                this.state.styleStatus.orientation == "landscape" &&
                isTablet
              ? { width: "90%" }
              : {}
          }
          className="thumb_icon_div"
        >
          <div className="container" ref={(node) => {
            if ((isMobile||isTablet)&& node &&  this.state.styleStatus.orientation === "landscape") {
                const containerWidth = window.screen.height
               const attainWidth =  window.screen.height *16 /9
              if (attainWidth) {
                node.style.setProperty(
                  "max-width",
                  `${attainWidth}px`,
                  "important"
                );
              }
            }
          }}>

          <div className="row">
          <div className="col-sm-6">

            <a className="creat-own-eternaview" onClick={()=>{ this.props.history.push(`/${this.baseName}/create/`); }} href="#">Create My Own Eternaview</a>
          </div>
          <div className="col-sm-6">

            <div
              className={
                sharedDetails?.screen_mode == "portrait" &&
                "thumb_icon_div_inner"
              }
            >
              <span
                className="span_tooltip tips-button-interact quick_tip"
                hover-tooltip="Quick Tips"
                tooltip-position="top"
                onClick={() => {
                  this.setState({ isTipsOpen: !this.state.isTipsOpen });
                }}
              >
                <img
                  style={{ height: "20px", width: 25, marginRight: 5 }}
                  src={LightBulb}
                />
                Tips
              </span>
              {this.state.noInteract == false && (
                <a
                  onClick={this.likeView}
                  className="thumb_a light_thumb"
                  href="#"
                >
                  <img src={hand_outer4} />
                  {showLike && (
                    <p className="custom_light">Your like is submitted!</p>
                  )}
                </a>
              )}
            </div>
            </div>
            </div>
          </div>
        </div>

        <section className="intract_voice_section">
          <div
          ref={(node) => {
            if (node &&(isMobile||isTablet)&&  this.state.styleStatus.orientation === "landscape") {
                const containerWidth = window.screen.height
               const attainWidth =  window.screen.height *16 /9
              if (attainWidth) {
                node.style.setProperty(
                  "max-width",
                  `${attainWidth}px`,
                  "important"
                );
              }
            }
          }}
          
          className="container">
            <div className="row">
              <div className="col-md-6 custom_six_col">
                <div className="begin_interact_title">
                  To begin interacting by voice:
                </div>
                <ul className="interact_ul">
                  <li>
                    <span className="numbering_spann">1</span>
                    {/* <i class="fas fa-check-circle"></i> */}
                    <span>
                      Click on the microphone icon above to begin recording.
                    </span>
                  </li>
                  <li>
                  <span className="numbering_spann">2</span>
                    <span>
                      Speak your question into your device's microphone.
                    </span>
                  </li>
                  <li>
                  <span className="numbering_spann">3</span>
                    <span>
                      Click the microphone icon again to stop recording.
                    </span>
                  </li>
                  <li>
                  <span className="numbering_spann">4</span>
                    <span>Wait to hear an answer!</span>
                  </li>
                </ul>
                <div className="begin_interact_title">
                To begin interacting by keyboard:
                </div>
                <ul className="interact_ul">
                  <li>
                  <span className="numbering_spann">1</span>
                    <span>Click on the keyboard icon above.</span>
                  </li>
                  <li>
                  <span className="numbering_spann">2</span>
                    <span>Type in your question then hit “Ask Question.”</span>
                  </li>
                  <li>
                  <span className="numbering_spann">3</span>
                    <span>Wait to hear an answer!</span>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 custom_six_col">
                <div className="title_interact_voice">
                  Title: {detail.title}
                </div>
                <div className="row">
                  <div className="col-md-6 custom_six_col">
                    <p className="record_title">Total Time Recorded:</p>
                    <p className="record_time">{detail.total_time}</p>
                  </div>
                  {/* <div className="col-md-6 custom_six_col">
                    <p className="record_title">Date Created:</p>
                    <p className="record_time">
                      {" "}
                      {Moment(detail.created_on).format("MM-DD-YYYY")}
                    </p>
                  </div> */}
                </div>
                <div className="row">
                  <div className="col-md-6 custom_six_col">
                    <p className="record_title"># Questions Answered:</p>
                    <p className="record_time">
                      {detail.total_question_recorded}
                    </p>
                  </div>
                 {/*  <div className="col-md-6 custom_six_col">
                    <p className="record_title">Last Updated:</p>
                    <p className="record_time">
                      {Moment(detail.updated_on).format("MM-DD-YYYY")}
                    </p>
                  </div> */}
                </div>
                <div className="row">
                  <div className="col-md-6 custom_six_col">
                    <p className="record_title">Bio:</p>
                    <p className="record_title">
                      {" "}
                      {detail.author__bio != ""
                        ? detail.author__bio
                        : "No bio added!"}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="nootic_div">
                      <b>Note:</b> You can also just click on the questions in
                      the “Topics” drop-down menu if you prefer to interact that
                      way.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
          id="myModal201"
          className={
            this.state.maxInteract == true
              ? "modal fade show custom_modal"
              : "modal fade show show_custom_modal"
          }
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close custom_close3"
                  onClick={this.closePopUp}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Sorry, this Eternaview has reached its maximum allowance in
                  terms of the queries that can be made to it. Please let the
                  creator know you would like to speak to their Eternaview so
                  that they may replenish their plan.
                </p>
              </div>
              <div className="modal-footer">
                <button type="button" onClick={this.closePopUp}>
                  Okay!
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          style={{ marginTop: "70px", maxWidth: "1000px" }}
          isOpen={this.state.isTipsOpen}
          toggle={() => {
            this.setState({ isTipsOpen: !this.state.isTipsOpen,secondPlayMuteStatus:false  });
            localStorage.setItem("tipsShown", "false");
          }}
          backdrop="static" // Prevent closing on outside click
          {...this.props}
        >
          <ModalHeader
            toggle={() => {
              this.setState({ isTipsOpen: !this.state.isTipsOpen,secondPlayMuteStatus:false  });
              localStorage.setItem("tipsShown", "false");
            }}
          >
            Tips
          </ModalHeader>
          <ModalBody className="topic_modal_body">
          {this.baseName=='dating'?<>
            <p>
              {" "}
              An Eternaview is interactive content recorded from a human. 
               It is not a live video chat. It’s also not an avatar or deepfake.{" "}
            </p>
            <p>
              {" "}
              It’s a low-pressure way to see and hear what someone is like to help decide if you’d like to meet in person.
            </p>
            <p>
            You can click to speak or type your questions, or click through the list of Topics.
            </p>
            <p>
            Since all creators recorded answers to the same set of general questions:
            </p>
            <ul style={{ listStyleType: "disc", marginLeft: "40px" }}>
              <li>
             
                It’s better to ask general questions like <b>“What kind of music do you like?” </b>
                rather than <b>“Would you go with me to the Bruno Mars  concert?”</b>{" "}
              </li>
              <li>
              Questions about relationship goals, values, and hobbies work better than asking about current events, math problems, or trivia.
              </li>
              <li>
              The creator won’t respond to their name and won’t know who you are.
              </li>
            </ul>
          </>:<>
                      <p>
              {" "}
              An Eternaview is content recorded from a human. It is not an
              avatar or deepfake.{" "}
            </p>
            <p>
              {" "}
              Use the <b>Topics</b> pop-up to explore questions that the
              Eternaview can answer, or ask your own questions in any way you
              prefer.
            </p>
            <p>
              When recording their Eternaview, all creators are given the same
              set of general questions, so it’s best that you also ask general
              questions about them.
            </p>
            <ul style={{ listStyleType: "disc", marginLeft: "40px" }}>
              <li>
                For instance, it’s better to ask <b>What was your job?</b>{" "}
                versus <b>Were you ever a vacuum salesman?</b>.
              </li>
              <li>
                Asking general questions about their life and family and friends
                should yield better results than asking about current events,
                math problems or the like.
              </li>
              <li>
                Treat the interaction as if you don’t know the creator
                personally. The creator won’t respond to their name or title and
                will not know your identity.
              </li>
            </ul>
            </>}

          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ auth: state.auth });
const mapDispatchToProps = (dispatch) => ({
  getChatGptViewerQuestions: bindActionCreators(getChatGptViewerQuestions, dispatch),
  postInteractQuestion: bindActionCreators(postInteractQuestion, dispatch),
  getEternaviewDetail: bindActionCreators(getEternaviewDetail, dispatch),
  getAnsweredQuestions: bindActionCreators(getAnsweredQuestions, dispatch),
  likeEternaview: bindActionCreators(likeEternaview, dispatch),
  verifyUserSubscription: bindActionCreators(verifyUserSubscription, dispatch),
  getParticularQuestionDetail: bindActionCreators(
    getParticularQuestionDetail,
    dispatch
  ),
  getInteractParticularQuestionDetail: bindActionCreators(
    getInteractParticularQuestionDetail,
    dispatch
  ),
  getQueryDetail: bindActionCreators(getQueryDetail, dispatch),
  ChatGPtText: bindActionCreators(ChatGPtText, dispatch),
  
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InteractOneTestscreenLive);

import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import thankyou_icon from "../../Images/thnku-icon.png";
import thankyou_text from "../../Images/thnku-text.png";
import FacebookPixel from '../../MetaComponents/Facebookpixel';
import { sendGiftEmailToUser,subscribeUserTogift } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import connection from '../../config/connection';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function GiftThankyou(props) {
    const auth = useSelector((state) => state);
    const dispatch = useDispatch();
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const recipentEmail = sessionStorage.getItem("giftRecipent");
    const [baseName, setBaseName] = useState('home');
    const [basePath, setBasePath] = useState(connection.homePage);

    const urlParams = new URLSearchParams(window.location.search);

    // Extract the 'receipt_name' parameter
    const receiptName = urlParams.get('receipt_name');
    

    const handleDefaultBehaviour = () =>{
        let subscriptionPayload = JSON.parse(sessionStorage.getItem("subscribeObj")) || {};
        //const subscriptionPayload =JSON.parse(sessionStorage.getItem("subscribeObj")) 

        //if(subscriptionPayload){
      /*  if (subscriptionPayload && Object.keys(subscriptionPayload).length !== 0) {

            subscriptionPayload.recipient_email = recipentEmail
            subscriptionPayload.plan_type = 'Dating';  // Add plan_type here

        }
        else
        {
            subscriptionPayload.recipient_email = receiptName;
            subscriptionPayload.plan_type = 'Dating';  // Add plan_type here
        } */
        if (subscriptionPayload && Object.keys(subscriptionPayload).length !== 0) {

            subscriptionPayload.recipient_email = recipentEmail;
            if(splitLocation[1]=='dating')
            {
                    subscriptionPayload.plan_type = 'Dating';
            }
            else  
            {
                        subscriptionPayload.plan_type = 'Legacy';
            }
            // subscriptionPayload.plan_type = 'Dating';  // Add plan_type here

        }
        else
        {
            subscriptionPayload.recipient_email = receiptName;
            if(splitLocation[1]=='dating')
            {
                    subscriptionPayload.plan_type = 'Dating';
            }
            else  
            {
                        subscriptionPayload.plan_type = 'Legacy';
            }
        }
        let token = false
        if(auth.auth.loginUserToken != "" || sessionStorage.getItem("token")) {
            token = true 
        }
       // console.log('subscriptionPayload',subscriptionPayload);

         dispatch(subscribeUserTogift(subscriptionPayload,token, (res) => {
            sessionStorage.removeItem("subscribeObj")

            dispatch(sendGiftEmailToUser({"recipient_email":receiptName,"baseName":splitLocation[1]}, (res) => {
                sessionStorage.removeItem("giftRecipent")
                window.location.href = `/${splitLocation[1]}/GiftThankyou/?success=true`;
              })); 

          }));
    }

    useEffect(()=>{
        if(receiptName){
            handleDefaultBehaviour()
        }
    },[])
   
    useEffect(() => {

        if(splitLocation[1]=='dating')
        {
         
          setBaseName('dating');
          setBasePath(connection.datingHomePage);
        }
        else
        {
            setBaseName('home');
            setBasePath(connection.homePage);
        }
      }, []);


    return (
        <>
   <FacebookPixel trackName="Purchase" obj={{value: 60, currency: "USD"}}/>
        <section class="thankyou-outer">
            <div class="container inner-container">
                <div class="row">
                    <div class="col-md-8 offset-md-2 col-sm-12">
                        <div class="login-inner thankyou-outer">
                            <div class="thankyou-upper">
                                <img src={thankyou_icon} alt="" />
                            </div>
                            <div class="thankyou-bottom">
                                <img src={thankyou_text} alt="" />
                                <p class="p-text">Your payment has been received. We hope the recipient likes their gift.
                                {/*  The subscription starts on the day you indicated to send the gift notification. */}
                                </p>
                                {/* <p class="p-text">Your payment has been received. You may now move on to recording.</p> */}
                            <Link      style={{ cursor: "pointer" }}  onClick={()=>{window.location.href =auth.auth.loginUserToken ? basePath + `?access_token=${auth.auth.loginUserToken}` : basePath}}>
                                <p      style={{ cursor: "pointer" }} className="new_user5">
                                   <a
                                    className="Puchase_now_btn_new"
                                    style={{ cursor: "pointer" }}
                                    >
                                    Continue 
                                   </a>
                                </p>
                            </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
};

export default GiftThankyou;
